import * as React from "react";

import Button from "../components/button";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Cta from "../components/cta";

const RegistryPage = () => (
  <Layout backgroundColor="yellow">
    <Seo title="Registry" />
    <h1>Registry</h1>
    <p>
      We are so thankful for the effort you’ll be making to join us on our
      special day! Celebrating with you is the most wonderful gift we could
      imagine. For friends and family who have expressed an interest, we have
      created an online registry for your convenience here:{" "}
    </p>

    <p>
      <Button
        text="Our registry"
        url="https://www.blueprintregistry.com/registry/micah-barrett-wedding-registry"
      />
    </p>

    <Cta url="/covid" text="Covid Safety Precautions" />
  </Layout>
);

export default RegistryPage;
